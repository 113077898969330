import React from 'react';
import { connect } from 'react-redux';

import Layout from '../../../components/layout';
import Container from '../../../components/container';

const ErrorCheckoutPage = (props) => {
    props.clearCart();

    return (
        <Layout>
            <Container>
                <h2>
                    Transaksi Gagal
                </h2>
            </Container>
        </Layout>
    )
}

const mapDispatchToProps = dispatch => ({
    clearCart: () => dispatch({ type: 'CLEAR_CART' })
})

export default connect(null, mapDispatchToProps)(ErrorCheckoutPage);